import * as  React from 'react';
import {
    BrowserRouter,
    Switch,
    Route
} from 'react-router-dom'
import './App.scss';
import {Home} from "./pages/home";
import {Nav, Navbar} from "react-bootstrap";
import logo from "./images/light-logo.png";
import {MDBCol, MDBContainer, MDBFooter, MDBRow} from "mdbreact";
import {faEnvelope, faPhone, faPhoneSlash, faPhoneSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faLinkedinIn, faTwitter} from "@fortawesome/free-brands-svg-icons";
import MainNavbar from "./components/navbar";
import {ContactUs} from "./pages/contact-us";
import {WhoWeAre} from "./pages/who-we-are";
import {ProjectPlan} from "./pages/project-plan";



const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/who-we-are" component={(props:any) => <WhoWeAre {...props}/>}/>
                <Route path="/contact-us/:option" component={(props:any) => <ContactUs {...props}/>}/>
                <Route path="/project-plan" render={(props:any) => <ProjectPlan {...props}/>}/>
                <Route path="/" render={(props => <Home {...props}/>)}/>
            </Switch>
        </BrowserRouter>
    );
};

export default App;
