import * as React from "react";
import MainNavbar from "../components/navbar";
import {CustomFooter} from "../components/custom_footer";
import {LabelledImages} from "../components/labelled_images";
import {MDBAvatar, MDBCard, MDBCardBody, MDBCardUp, MDBIcon} from "mdbreact";
import {RouteComponentProps} from "react-router";
import classnames from "classnames";

export interface IWhoWeAreProps extends RouteComponentProps<any> {
    history: any
}

export interface IWhoWeAreState {
    principle: string;
    labelledImage: LabelledImages;
}

export class WhoWeAre extends React.Component<RouteComponentProps<any>, IWhoWeAreState> {

    constructor(props: IWhoWeAreProps) {
        super(props);
    }

    handleRedirect(path: string) {
        this.props.history.push(path);
    }

    render() {
        return (
            <>
                <div className={classnames("theme-background", "min-vh-100 d-flex flex-column")}>
                    <MainNavbar colour="plain-navbar" dark={false}/>
                    <div className="pb-3 d-flex justify-content-center">
                        <div className="col-12 col-sm-11 d-flex align-items-center justify-content-center flex-column">
                            <div className="col-8 col-md-6 text-center mb-1 ">
                                The Visible Hands Project is a start up charity which aims to address systemic
                                poverty
                                by
                                equiping small businesses with the tools they require in order to attain key philanthropic
                                goals.
                            </div>
                            <div className="d-flex flex-row">
                                <a className="blockLink" href="https://blog.visiblehandsproject.com">VISIT OUR
                                    BLOG</a>
                                <a className="blockLink" href="/contact-us/newsletter">STAY IN TOUCH</a>
                                <a className="blockLink" href="https://www.instagram.com/visiblehandsproject">INSTAGRAM</a>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow-1 container-fluid d-flex mt-5 justify-content-center flex-column flex-sm-row">
                        <div className="col-12 col-sm-5 mr-sm-3 mb-3">
                            <MDBCard testimonial>
                                <MDBCardUp className='unique-color-dark lighten-1'/>
                                <MDBAvatar className='mx-auto white' style={{width:"80px", height:"80px"}}>
                                    <img
                                        src={'../images/bola_photo.jpg'}
                                        alt=''
                                    />
                                </MDBAvatar>
                                <MDBCardBody>
                                    <h4 className='card-title'>Bola</h4>
                                    <hr/>
                                    <p>
                                        <MDBIcon icon='quote-left'/> Outside of this, I'm a 20-something year old
                                        woman starting a career in Finance as a portfolio manager. I'm a half-glass full individual who's
                                        deeply passionate about taking steps to addressing poverty and helping the world become a better place!
                                        <MDBIcon icon='quote-right'/>
                                    </p>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                        <div className="col-12 col-sm-5 mb-3">
                            <MDBCard testimonial>
                                <MDBCardUp className='stylish-color-dark lighten-1'/>
                                <MDBAvatar className='mx-auto white' style={{width:"80px", height:"80px"}}>
                                    <img
                                        src={'../images/bukky_photo.jpg'}
                                        alt=''
                                    />
                                </MDBAvatar>
                                <MDBCardBody>
                                    <h4 className='card-title'>Victoria</h4>
                                    <hr/>
                                    <p>
                                        <MDBIcon icon='quote-left'/>
                                        I am a student studying Computer Science and Mathematics starting my career in Technology. I'm incredibly keen
                                        on upskilling and transforming the lives of people who have been marginalised. I'm hoping my contributions to VHP can help shift the needle!
                                        <MDBIcon icon='quote-right'/>
                                    </p>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                    </div>
                    <CustomFooter classNames=""/>
                </div>
            </>
        );
    }
}
