import * as React from 'react';
import {LabelledImages} from "../components/labelled_images";
import {MDBAnimation, MDBCol, MDBIcon, MDBRow} from "mdbreact";
// @ts-ignore
import classnames from "classnames";
import MainNavbar from "../components/navbar";
import {CustomFooter} from "../components/custom_footer";
import {RouteComponentProps} from "react-router-dom";

interface IHomePageProps extends RouteComponentProps<any> {
}

interface IHomePageState {
    opacity: number;
    firstLoadExperience: boolean;
    typingText: string;
    currentReelIndex: number;
    firstExperienceTextColour: string;
    underlineClass: string;
    boldenClass: string;
    doubleLineClass: string;
    bgHighlightClass: string;
    textRevealClass: string
    underlineErrClass: string
}

export class Home extends React.Component<IHomePageProps, IHomePageState> {

    history: any;
    reel = ["We're twisting the rules.", "Reinventing the wheel.", "Turning the tables.", "Because we believe in change.", "Because we are change."];
    mainProjectText = "The Visible Hands Project.";
    opacityReducer = 0;

    timeoutFunction: any;

    constructor(props: IHomePageProps) {
        super(props);
        this.state = {
            firstLoadExperience: true,
            opacity: 1,
            typingText: "",
            currentReelIndex: 0,
            firstExperienceTextColour: "#ffffff",
            underlineClass: "",
            boldenClass: "",
            doubleLineClass: "",
            bgHighlightClass: "",
            textRevealClass: "",
            underlineErrClass: "",
        };
        this.handleRedirect = this.handleRedirect.bind(this);
        this.calculateOpacityReducer();

    }

    componentDidMount = () => {
        this.loadText();
    };

    calculateOpacityReducer = () => {
        let totalSize = 0;
        this.reel.forEach(textStrip => {
            totalSize += textStrip.length;
        });
        totalSize *= 2;
        this.opacityReducer = 1 / totalSize;
    };


    getLabelledImagesForHome = (): LabelledImages[] => {
        let images: LabelledImages[] = [];
        images.push(new LabelledImages("../images/holding-hands.jpeg", "A message of hope", "We are commited to enabling marginalised entrepreneurs to find out their true potential"));
        images.push(new LabelledImages("../images/future.JPG", "Breaking poverty's chains", "These chains break quite easily when we apply the right force"));
        images.push(new LabelledImages("../images/new-day.JPG", "A new sunrise", "With our volunteers we can create a new day for our communities"));
        return images;
    };

    firstExperienceColourDeterminer = (newOpacity: number) => {
        return "#0f0f0f";
    };

    goToMainProjectText = () => {
        if (this.timeoutFunction) {
            clearTimeout(this.timeoutFunction);
        }
        this.setState({

            firstLoadExperience: false
        });
        this.typeText(this.mainProjectText, 0, false);

    };

    loadText = () => {
        if (this.state.currentReelIndex < this.reel.length) {
            const textStrip = this.reel[this.state.currentReelIndex];
            this.typeText(textStrip, 0, true);
        } else {
            this.goToMainProjectText();
        }
    };

    typeText = (text: string, index: number, canBackSpace: boolean) => {
        let newOpacity = this.state.opacity - this.opacityReducer;
        this.setState({
            typingText: index === 0 ? text[index] : this.state.typingText + text[index],
            opacity: newOpacity,
            firstExperienceTextColour: this.firstExperienceColourDeterminer(newOpacity),
        });
        if (index < text.length - 1) {
            this.timeoutFunction = setTimeout(this.typeText, 15, text, index + 1, canBackSpace);
        } else if (canBackSpace) {
            this.timeoutFunction = setTimeout(this.backspaceText, 800);
        }
    };

    backspaceText = () => {
        let newOpacity = this.state.opacity - this.opacityReducer;
        this.setState({
            typingText: this.state.typingText.substr(0, this.state.typingText.length - 1),
            opacity: newOpacity,
            firstExperienceTextColour: this.firstExperienceColourDeterminer(newOpacity),
        });
        if (this.state.typingText.length > 0) {
            this.timeoutFunction = setTimeout(this.backspaceText, 15);
        } else {
            this.setState({
                currentReelIndex: this.state.currentReelIndex + 1,
            });
            this.loadText();
        }
    };

    onLogoAnimationEnd = () => {
        // if (!this.state.logoCanSlideOut) {
        //     this.setState({
        //         logoCanSlideOut: true,
        //         canAnimateLogo: false
        //     });
        // }
    };

    handleRedirect(path: string) {
        this.props.history.push(path);
    }

    render() {

        if (this.state.firstLoadExperience) {
            return this.firstLoadExperience();
        } else {
            return this.mainPage();
        }
    }


    firstLoadExperience = () => {
        let backgroundColourString = "rgba(221, 134, 138, " + this.state.opacity + ")";
        return (
            <div className={classnames("theme-background", "vh-100", "container-fluid p-0")} onClick={() => {
                this.goToMainProjectText();
            }}>
                <div style={{background: backgroundColourString}} className="h-100">
                    <MainNavbar colour="plain-navbar" dark={false} style={{opacity: 1 - this.state.opacity}}/>
                    <div className="row h-75 w-100">
                        <div className="col-12 d-flex align-items-center justify-content-center flex-column">
                            <div className="c-text-large p-0" style={{
                                color: this.state.firstExperienceTextColour,
                                opacity: 1.0,
                                fontFamily: "'Khand', sans-serif"
                            }}>

                                {this.state.typingText}
                            </div>
                            <div className="text-smaller font-italic text-muted" style={{display: "block"}}>Tap to skip
                                experience
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    };

    mainPage = () => {
        return (<>
            <div className={classnames("theme-background", "d-flex flex-column adjust-height")}>
                <MainNavbar colour="plain-navbar" dark={false}/>
                <div className={"d-flex flex-grow-1 container-fluid flex-column"}>
                    <div className="row h-75">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <span className={"animated-large-name mt-5"}>{this.state.typingText}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classnames("container-fluid", "pr-2", "pl-2", "pr-md-3", "pl-md-3")}
                 style={{backgroundColor: "rgba(213,213,213,0.36)"}}>
                <section className="pt-3 pb-3">
                    <h2 className="h1-responsive font-weight-bold text-center special-text">
                        Core Principles
                    </h2>
                    <p className="section-text w-responsive text-center mx-auto mb-5">
                        Our core tenets are at the heart of why we're different.
                    </p>

                    <MDBRow className={classnames("section", "pt-5", "mr-0", "ml-0", "mr-md-3", "ml-md-3")}
                    >
                        <MDBCol lg="5" className="mb-lg-0 mb-5 d-flex flex-column align-items-center">
                            <h2 className={"special-text text-center"}>Sustainable Philanthropy</h2>
                            <img className="img-fluid" src={'../images/sustainable-philanthropy.gif'} width="60%"/>
                        </MDBCol>
                        <MDBCol lg="7">
                            <MDBRow className="mb-3">
                                <MDBCol md="11" size="10">
                                    <MDBAnimation type="fadeInRight" duration={2} delay={"2s"} onAnimationEnd={() => {
                                        this.setState({
                                            underlineClass: "inline-text-underline",
                                            boldenClass: "inline-text-bolden",
                                        });
                                    }}>
                                        <h5 className="font-weight-bold mb-3 special-text">Going Farther</h5>
                                        <p className="section-text">
                                            We believe that charitable giving can go farther than it currently does.
                                            There's a
                                            <span className={classnames(this.state.underlineClass)}> fundamental question </span>
                                            faced by businesses and organisations all around the world - How do we make
                                            the money we
                                            spend
                                            generate the
                                            highest returns possible? In our case, we're asking how do we
                                            <span
                                                className={classnames(this.state.boldenClass)}> stretch donations </span> we
                                            receive
                                            so that
                                            we can touch as many lives as possible?
                                        </p>
                                    </MDBAnimation>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mb-3">
                                <MDBCol md="11" size="10">
                                    <MDBAnimation type="fadeInLeft" reveal duration={2} onAnimationEnd={() => {
                                        this.setState({
                                            doubleLineClass: "inline-text-overline-underline",
                                        });
                                    }}>
                                        <h5 className="font-weight-bold mb-3 special-text text-right">Lending</h5>
                                        <p className="section-text text-right">
                                            We don't have to look far to find the answer to this question. We'll take a
                                            page from the
                                            economist's handbook. In the real world,
                                            the <span className={classnames(this.state.doubleLineClass)}>multiplicative effect</span> of
                                            money is facilitated by banks simply lending money. Just the
                                            process of
                                            lending money increases the supply of money
                                            in the economy in factors of a "reserve ratio".
                                        </p>
                                    </MDBAnimation>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mb-3">
                                <MDBCol md="11" size="10">
                                    <MDBAnimation type="fadeInRight" reveal duration={2} onAnimationEnd={() => {
                                        this.setState({
                                            bgHighlightClass: "bg-highlight"
                                        });
                                    }}>
                                        <h5 className="font-weight-bold mb-3 special-text">A twist</h5>
                                        <p className="section-text">
                                            We're proposing to twist this concept on its head and apply it to
                                            philanthropy. By taking an
                                            <em className={classnames(this.state.bgHighlightClass)}>interventionist
                                                approach</em>,
                                            We inject capital into small businesses with clear, defined, bespoke
                                            objectives in mind; The
                                            overarching goal being to attain those objectives
                                            that will then allow us to extract the capital back out of the business
                                            whilst leaving the
                                            business
                                            better able to support itself and its community.
                                        </p>
                                    </MDBAnimation>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mb-3">

                                <MDBCol md="11" size="10">
                                    <MDBAnimation type="fadeInLeft" reveal duration={2} onAnimationEnd={() => {
                                        this.setState({
                                            textRevealClass: "text-reveal"
                                        });
                                    }}>
                                        <h5 className="font-weight-bold mb-3 special-text text-right">Simple
                                            Incentives</h5>
                                        <p className="section-text text-right">
                                            We want to be able lend and yes, have the capital <span
                                            className={classnames(this.state.textRevealClass)}> returned </span>to us so
                                            we can
                                            transform as many
                                            lives as possible. We want to incentivise potential donors
                                            as their money will not stop changing lives long after they've donated. We
                                            also want to
                                            incentivise
                                            the businesses we work with to push hard so that
                                            they're able to continue earning levels of income sufficient to support the business even after we've
                                            extracted the kickstarting
                                            funding out of
                                            the business.
                                        </p>
                                    </MDBAnimation>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mb-3">
                                <MDBCol md="11" size="10">
                                    <MDBAnimation type="wobble" reveal duration={2} onAnimationEnd={() => {
                                        this.setState({
                                            underlineErrClass: "inline-text-underline-red"
                                        });
                                    }}>
                                        <h5 className="font-weight-bold mb-3 special-text">Moving Parts</h5>
                                        <p className="section-text">
                                            And with all these parts moving together, we believe our model will slowly,
                                            but surely chip
                                            away the
                                            issue of <span
                                            className={classnames(this.state.underlineErrClass)}>poverty</span> in our
                                            communities and eventually, the world.
                                            Our beliefs are borne from the one core truth in today's market economies -
                                            the only means
                                            by which
                                            we can eradicate poverty is by facilitating economic growth.
                                        </p>
                                    </MDBAnimation>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <hr className="my-5"/>
                    <MDBRow className={classnames("section", "pt-5", "mr-0", "ml-0", "mr-md-3", "ml-md-3")}>
                        <MDBCol lg="5" className="mb-lg-0 mb-5 d-flex flex-column align-items-center">
                            <h2 className={"special-text text-center"}>Leveraging Skills</h2>
                            <img className="img-fluid" src={'../images/leveraging-skills.gif'} width="60%"/>
                        </MDBCol>
                        <MDBCol lg="7">
                            <MDBRow className="mb-3">
                                <MDBCol md="11" size="10">
                                    <MDBAnimation reveal duration={2} type="slideInLeft">
                                        <h5 className="font-weight-bold mb-3 special-text">Not By Ourselves Please!</h5>
                                        <p className="section-text">
                                            We don't think we can do all this <span className={classnames("share")}>
                                            <span>t</span>
                                            <span>r</span>
                                            <span>a</span>
                                            <span>n</span>
                                            <span>s</span>
                                            <span>f</span>
                                            <span>o</span>
                                            <span>r</span>
                                            <span>m</span>
                                            <span>a</span>
                                            <span>t</span>
                                            <span>i</span>
                                            <span>o</span>
                                            <span>n</span>
                                        </span>
                                            by ourselves. In fact, we'd be far less effective if we
                                            tried to do it all by ourselves. Hence, we want to enlist you, the skilled
                                            worker.
                                        </p>
                                    </MDBAnimation>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mb-3">
                                <MDBCol md="11" size="10">
                                    <MDBAnimation reveal duration={2} type="slideInRight">
                                        <h5 className="font-weight-bold mb-3 special-text text-right">A Great
                                            Connector</h5>
                                        <p className="section-text text-right">
                                            We want to be a <MDBIcon fab icon="connectdevelop" size="3x"
                                                                     spin/> connector between people who are
                                            knowledgeable about various fields and occupations either via
                                            education or vocation and those who are less skilled. We want to connect
                                            farmers to agriculaturists, connect successful
                                            fashion designers to the local tailor, connect successful hairdressers to
                                            the local salon - summarily, we want those higher up the ladder to reach
                                            a hand down and pull others up with them.
                                        </p>
                                    </MDBAnimation>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <hr className="my-5"/>
                    <MDBRow className={classnames("section", "pt-5", "mr-0", "ml-0", "mr-md-3", "ml-md-3")}>
                        <MDBCol lg="5" className="mb-lg-0 mb-5 d-flex flex-column align-items-center">
                            <h2 className={"special-text text-center"}>Transforming Businesses</h2>
                            <img className="img-fluid" src={'../images/transforming-business.gif'} width="60%"/>
                        </MDBCol>
                        <MDBCol lg="7">
                            <MDBRow className="mb-3">
                                <MDBCol md="11" size="10">
                                    <MDBAnimation type="fadeInLeft" reveal duration={2} onAnimationEnd={() => {
                                        this.setState({});
                                    }}>
                                        <h5 className="font-weight-bold mb-3 special-text">There are nuances</h5>
                                        <p className="section-text">
                                            You've heard of micro-finance institutions <MDBIcon icon="university"
                                                                                                size="2x"/> and so have
                                            we. You've heard of skills workshops and so have we. We however think that
                                            the solution to the problems faced by
                                            our communities is not simply throwing money at the largely unskilled
                                            workforce. We need to equip them to be able to stand on their own two
                                            feet. <MDBAnimation type="fadeIn" infinite style={{"display": "inline"}}><MDBIcon icon="shoe-prints" size="2x" className="amber-text"/></MDBAnimation>
                                        </p>
                                    </MDBAnimation>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className="mb-3">

                                <MDBCol md="11" size="10">
                                    <MDBAnimation type="fadeInRight" reveal duration={2} onAnimationEnd={() => {
                                    }}>
                                        <h5 className="font-weight-bold mb-3 special-text text-right">Multi-layered
                                            Support</h5>
                                        <p className="section-text text-right">
                                            With a multi-layered support system, we are hoping that we are able to truly
                                            and continuously provide support to small businesses even after they've
                                            rolled off our transformative program.
                                            Although this is the last of our tenets, we believe it's the most important
                                            in order to ensure that the level of success we attain for each business is
                                            maintained without our support.
                                            The ability to understand how to balance the books, plan for the future,
                                            pursue growth and returns is fundamental to every business and we're hoping
                                            that by equiping the everyday entrepreneur into
                                            multi-skilled individuals, they will be able to grow their businesses long
                                            after we've disengaged from them.
                                        </p>
                                    </MDBAnimation>
                                </MDBCol>
                            </MDBRow>

                        </MDBCol>
                    </MDBRow>
                    <hr className="my-5"/>
                    <MDBRow className={classnames("section", "pt-5", "mr-0", "ml-0", "mr-md-3", "ml-md-3")}
                    >
                        <MDBCol lg="5" className="mb-lg-0 mb-5 d-flex flex-column align-items-center">
                            <h2 className={"special-text text-center"}>Discussion Platform</h2>
                            <img className="img-fluid" src={'../images/discussion-platform.gif'} width="60%"/>
                        </MDBCol>
                        <MDBCol lg="7" className={classnames("d-flex align-items-center")}>
                            <MDBRow className="mb-3">

                                <MDBCol md="11" size="10">
                                    <p className="section-text text-right">
                                        We're a chatter box. We just love talking. <MDBIcon icon="comment-alt"
                                                                                            size="2x"/> About social
                                        issues, politics and just life in general. Of course, with the overarching theme
                                        of how this affects
                                        running small businesses. We write articles and we encourage you to engage with
                                        us in this way even if nothing else appeals to you.
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </section>
            </div>
            <CustomFooter classNames=""/>
        </>);
    }
}