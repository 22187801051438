import * as React from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow, MDBSelect} from "mdbreact";
import MainNavbar from "../components/navbar";
import {Connect} from "aws-amplify-react";
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from '../graphql/mutations';
import {Education, Field, Hours, Period} from "../API";
import {CustomFooter} from "../components/custom_footer";
import classnames from "classnames";

export interface IContactUsProps {
    match: any;
    history: any;
}

interface IContactUsState {
    contact: string;
}

export class ContactUs extends React.Component<IContactUsProps, IContactUsState> {

    mappings: { [key: string]: JSX.Element } = {
        "newsletter": <NewsLetter/>,
        "volunteer": <Volunteer/>,
        "donor": <Donor/>,
    };

    constructor(props: IContactUsProps) {
        super(props);

        const match = this.props.match as any;
        const {option} = match.params;
        this.handleRedirect = this.handleRedirect.bind(this);
        this.state = {
            contact: option
        }
    }

    handleRedirect(path: string) {
        this.props.history.push(path);
    }

    componentWillReceiveProps(nextProps: Readonly<IContactUsProps>, nextContext: any): void {
        if (this.props.match !== nextProps.match) {
            this.updateState(nextProps);
        }
    }

    updateState = (nextProps: Readonly<IContactUsProps>) => {
        const match = nextProps.match as any;
        const {option} = match.params;
        this.setState({
            contact: option,
        })
    };

    render() {
        let isSmallScreen = false;
        if (matchMedia("(max-width: 575px)").matches) {
            isSmallScreen = true;
        }
        const view: JSX.Element = this.mappings[this.state.contact];
        return (
            <div className={classnames("theme-background", "min-vh-100 d-flex flex-column")}>
                <MainNavbar colour="plain-navbar" dark={false}/>

                <div className="d-flex flex-column flex-sm-row flex-grow-1">
                    <div
                        className="col-12 col-sm-3 d-flex flex-row justify-content-center justify-content-sm-start flex-sm-column align-items-center">
                        {/*<MDBBtnGroup className="mr-2 mt-2" vertical={isSmallScreen}>*/}
                        <MDBBtn rounded floating size="lg" color={this.state.contact == "volunteer" ? "blue-grey": "elegant"}
                                onClick={() => this.handleRedirect("volunteer")} ><MDBIcon icon="hands-helping" />
                        </MDBBtn>
                        <MDBBtn rounded floating color={this.state.contact == "newsletter" ? "blue-grey": "elegant"} size="lg"
                                onClick={() => this.handleRedirect("newsletter")}><MDBIcon icon="newspaper"/>
                        </MDBBtn>
                        <MDBBtn rounded floating size="lg" color={this.state.contact == "donor" ? "blue-grey": "elegant"}
                                onClick={() => this.handleRedirect("donor")}> <MDBIcon icon="donate"/></MDBBtn>
                        {/*</MDBBtnGroup>*/}
                    </div>
                    <div
                        className="col-12 col-sm-9 d-flex flex-row align-items-start mt-5">
                        {view}
                    </div>
                </div>
                <CustomFooter classNames=""/>
            </div>
        );
    }
};

interface PanelState {
    formActivePanel: number,
    formActivePanelChanged: boolean,

}

class NewsLetter extends React.Component<{}, PanelState> {
    private firstName: React.RefObject<HTMLInputElement>;
    private lastName: React.RefObject<HTMLInputElement>;
    private email: React.RefObject<HTMLInputElement>;

    constructor(props: any) {
        super(props);

        this.state = {
            formActivePanel: 1,
            formActivePanelChanged: false,
        };

        this.firstName = React.createRef();
        this.lastName = React.createRef();
        this.email = React.createRef();
    }

    handleSubmitClick = (param: number, mutation: any) => async (e: React.FocusEvent<HTMLFormElement>) => {
        e.preventDefault();

        e.target.className += " was-validated";

        if ((this.firstName as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value && (this.lastName as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value && (this.email as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value) {
            const input = {
                firstName: (this.firstName as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value,
                secondName: (this.lastName as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value,
                email: (this.email as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value
            };
            try {
                await mutation.mutation({input});
                this.setState({
                    formActivePanel: param,
                    formActivePanelChanged: true
                });
            } catch (e) {
                console.error(e)
            }
        }
    };

    render() {
        return (
            <MDBContainer>

                <MDBRow className="">
                    <MDBCol md="7">
                        {this.state.formActivePanel === 1 && (
                            <MDBCol md="12">
                                <Connect mutation={graphqlOperation(mutations.createUser)}>
                                    {(mutation: any) => (
                                        <form onSubmit={this.handleSubmitClick(2, mutation)}
                                              className="needs-validation" noValidate>
                                            <h3 className="font-weight-bold pl-0 my-4 special-text">
                                                <strong>Sign Up To Our Newsletter</strong>
                                            </h3>
                                            <MDBInput label="First Name" className="mt-4" icon="user-circle"
                                                      name="fname"
                                                      autoFocus={true} required inputRef={ref => this.firstName = ref}>
                                                <div className="invalid-feedback  ml-4 pl-3">Provide a valid name!</div>
                                            </MDBInput>
                                            <MDBInput label="Last Name" className="mt-4" icon="-" required name="lname"
                                                      inputRef={ref => this.lastName = ref}>
                                                <div className="invalid-feedback  ml-4 pl-3">Provide a valid name!</div>
                                            </MDBInput>
                                            <MDBInput label="Email" className="mt-4" icon="at" required name="email"
                                                      type="email" inputRef={ref => this.email = ref}>
                                                <small id="emailHelp" className="form-text text-muted">
                                                    We'll never share your email with anyone else.
                                                </small>
                                                <div className="invalid-feedback  ml-4 pl-3">Provide a valid email!
                                                </div>
                                            </MDBInput>
                                            <MDBBtn color="elegant" rounded className="float-right" type="submit">
                                                submit
                                            </MDBBtn>
                                        </form>
                                    )}
                                </Connect>
                            </MDBCol>
                        )}
                        {this.state.formActivePanel === 2 && (
                            <MDBCol md="12">

                                <h2 className="text-center font-weight-bold my-4 special-text">
                                    Registration completed!
                                </h2>
                            </MDBCol>
                        )}
                    </MDBCol>
                </MDBRow>

            </MDBContainer>
        );
    };
}

class Volunteer extends React.Component<{}, PanelState> {
    private firstName: React.RefObject<HTMLInputElement>;
    private lastName: React.RefObject<HTMLInputElement>;
    private email: React.RefObject<HTMLInputElement>;
    private educationOptions: {}[] = [
        {
            text: "Secondary School",
            value: "HIGH_SCHOOL"
        },
        {
            text: "Undergraduate",
            value: "UNDERGRAD"
        },
        {
            text: "Masters",
            value: "MASTERS"
        },
        {
            text: "PhD",
            value: "PHD"
        }
    ];
    private skillsOptions: {}[] = [
        {
            text: "Accountancy",
            value: "ACCOUNTANCY"
        },
        {
            text: "Agriculture",
            value: "AGRICULTURE"
        },
        {
            text: "Architecture",
            value: "ARCHITECTURE"
        },
        {
            text: "Arts",
            value: "ARTS"
        },
        {
            text: "Business Administration",
            value: "BUSINESS_ADMINISTRATION"
        },
        {
            text: "Communications",
            value: "COMMUNICATIONS"
        },
        {
            text: "Construction",
            value: "CONSTRUCTION"
        },
        {
            text: "Education",
            value: "EDUCATION"
        },
        {
            text: "Engineering",
            value: "ENGINEERING"
        },
        {
            text: "Environment",
            value: "ENVIRONMENT"
        },
        {
            text: "Finance",
            value: "FINANCE"
        },
        {
            text: "Government",
            value: "GOVERNMENT"
        },
        {
            text: "Health & Medicine",
            value: "HEALTH_MEDICINE"
        },
        {
            text: "Information Technology",
            value: "INFORMATION_TECHNOLOGY"
        },
        {
            text: "Law",
            value: "LAW"
        },
        {
            text: "Sciences",
            value: "SCIENCES"
        },
        {
            text: "Social Impact",
            value: "SOCIAL_IMPACT"
        },

    ];
    private hoursOptions: {}[] = [
        {
            text: "1",
            value: "ONE_HOUR",
        },
        {
            text: "2",
            value: "TWO_HOURS",
        },
        {
            text: "3",
            value: "THREE_HOURS",
        },
        {
            text: "5",
            value: "FIVE_HOURS"
        }];
    private periodOptions: {}[] = [
        {
            text: "Day",
            value: "DAY",
        },
        {
            text: "Week",
            value: "WEEK",
        },
        {
            text: "Month",
            value: "MONTH",
        },
    ];
    private selections: {
        skills?: Field[],
        educationLevel?: Education,
        availabilityHours?: Hours,
        availabilityPeriod?: Period,
    } = {};

    constructor(props: any) {
        super(props);

        this.state = {
            formActivePanel: 1,
            formActivePanelChanged: false,
        };

        this.firstName = React.createRef();
        this.lastName = React.createRef();
        this.email = React.createRef();
    }

    handleSelectChange = (param: string) => (e: any) => {
        //@ts-ignore
        this.selections[param] = e
    };

    handleSubmitClick = (param: number, mutation: any) => async (e: React.FocusEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.target.className += " was-validated";

        if ((this.firstName as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value
            && (this.lastName as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value
            && (this.email as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value
            && (this.selections.educationLevel) && (this.selections.skills)
            && (this.selections.availabilityHours) && (this.selections.availabilityPeriod)) {

            const user = {
                firstName: (this.firstName as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value,
                secondName: (this.lastName as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value,
                email: (this.email as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value
            };

            let response = await API.graphql(graphqlOperation(mutations.createUser, {input: user}));
            //@ts-ignore
            let userId = response.data.createUser.id;
            const input = {
                //@ts-ignore
                skills: Field[this.selections.skills],
                //@ts-ignore
                educationLevel: Education[this.selections.educationLevel],
                //@ts-ignore
                availabilityHours: Hours[this.selections.availabilityHours],
                //@ts-ignore
                availabilityPeriod: Period[this.selections.availabilityPeriod],
                userId: userId

            };

            try {
                await mutation.mutation({input});
                this.setState({
                    formActivePanel: param,
                    formActivePanelChanged: true
                });
            } catch (e) {
                console.error(e)
            }
        }
    };

    render() {
        return (
            <MDBContainer>
                <MDBRow className="">
                    <MDBCol md="7">
                        {this.state.formActivePanel === 1 && (
                            <MDBCol md="12">
                                <Connect mutation={graphqlOperation(mutations.createVolunteer)}>
                                    {(mutation: any) => (<form onSubmit={this.handleSubmitClick(2, mutation)}
                                                               className="needs-validation" noValidate>
                                        <h3 className="font-weight-bold pl-0 my-4 special-text">
                                            <strong> Volunteer - Personal Info</strong>
                                        </h3>
                                        <MDBInput label="First Name" className="mt-4" icon="user-circle"
                                                  name="fname"
                                                  autoFocus={true} required inputRef={ref => this.firstName = ref}>
                                            <div className="invalid-feedback  ml-4 pl-3">Provide a valid name!</div>
                                        </MDBInput>

                                        <MDBInput label="Last Name" className="mt-4" icon="-" required name="lname"
                                                  inputRef={ref => this.lastName = ref}>
                                            <div className="invalid-feedback  ml-4 pl-3">Provide a valid name!</div>
                                        </MDBInput>
                                        <MDBInput label="Email" className="mt-4" icon="at" required name="email"
                                                  type="email" inputRef={ref => this.email = ref}>
                                            <small id="emailHelp" className="form-text text-muted">
                                                We'll never share your email with anyone else.
                                            </small>
                                            <div className="invalid-feedback  ml-4 pl-3">Provide a valid email!
                                            </div>
                                        </MDBInput>
                                        <div className="d-flex flex-row">
                                            <MDBIcon icon="university" className="col-1 p-0 pr-5 pr-sm-3" size="2x"/>
                                            <MDBSelect
                                                options={this.educationOptions}
                                                selected="Choose your education level"
                                                label="Education Level"
                                                multiple={false}
                                                color="unique-color-dark"
                                                className="col-11 m-0 p-0"
                                                getValue={this.handleSelectChange("educationLevel")}
                                                required
                                            />
                                        </div>
                                        <div className="d-flex flex-row">
                                            <MDBIcon icon="user-tie" className="col-1 p-0 pr-5 pr-sm-3" size="2x"/>
                                            <MDBSelect
                                                options={this.skillsOptions}
                                                selected="Choose your option"
                                                label="Skills (Choose all that apply)"
                                                multiple={true}
                                                color="unique-color-dark"
                                                className="col-11 m-0 p-0"
                                                getValue={this.handleSelectChange("skills")}
                                                required

                                            />
                                        </div>
                                        <div className="d-flex flex-row-reverse ">
                                            <MDBCol md="6" className="pr-0">
                                                <MDBSelect
                                                    options={this.periodOptions}
                                                    selected="Choose your option"
                                                    label="Per Period"
                                                    multiple={false}
                                                    color="unique-color-dark"
                                                    className="m-0 p-0"
                                                    getValue={this.handleSelectChange("availabilityPeriod")}
                                                    required
                                                />
                                            </MDBCol>

                                            <MDBCol md="5" className="p-0">
                                                <MDBSelect
                                                    options={this.hoursOptions}
                                                    selected="Choose your option"
                                                    label="Hours Available"
                                                    multiple={false}
                                                    color="unique-color-dark"
                                                    className="m-0 p-0"
                                                    getValue={this.handleSelectChange("availabilityHours")}
                                                    required
                                                />
                                            </MDBCol>
                                            <MDBCol md="1" className="col-2 p-0">
                                                <MDBIcon icon="calendar-week" size="2x" className="mr-2 mr-sm-0 p-0"/>
                                            </MDBCol>
                                        </div>
                                        <MDBBtn color="elegant" rounded className="float-right" type="submit">
                                            submit
                                        </MDBBtn>
                                    </form>)}
                                </Connect>
                            </MDBCol>
                        )}
                        {this.state.formActivePanel === 2 && (
                            <MDBCol md="12">
                                <h2 className="text-center font-weight-bold my-4 special-text">
                                    Registration completed!
                                </h2>
                            </MDBCol>
                        )}
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }

}

interface DonorState {
    checkedRecurrent: boolean
}

class Donor extends React.Component<{}, DonorState & PanelState> {
    private firstName: React.RefObject<HTMLInputElement>;
    private lastName: React.RefObject<HTMLInputElement>;
    private email: React.RefObject<HTMLInputElement>;
    private amount: React.RefObject<HTMLInputElement>;
    private recurring: React.RefObject<HTMLInputElement>;

    private currencyOptions: {}[] = [
        {
            text: "£",
            value: "GBP",
        },
        {
            text: "₦",
            value: "NAIRA",
        }];

    private selections: {
        currency?: string,
    } = {};

    constructor(props: any) {
        super(props);

        this.state = {
            formActivePanel: 1,
            formActivePanelChanged: false,
            checkedRecurrent: false
        };

        this.firstName = React.createRef();
        this.lastName = React.createRef();
        this.email = React.createRef();
        this.amount = React.createRef();
        this.recurring = React.createRef();
    }

    handleSelectChange = (param: string) => (e: any) => {
        //@ts-ignore
        this.selections.currency = e
    };

    handleChecked = (e: any) => {
        //@ts-ignore
        this.setState({
            checkedRecurrent: !this.state.checkedRecurrent
        });

    };

    handleSubmitClick = (param: number, mutation: any) => async (e: React.FocusEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.target.className += " was-validated";

        if ((this.firstName as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value
            && (this.lastName as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value
            && (this.email as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value
            && (this.amount as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value
            && (this.state.checkedRecurrent)
            && (this.selections.currency)) {

            const user = {
                firstName: (this.firstName as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value,
                secondName: (this.lastName as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value,
                email: (this.email as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value
            };

            let response = await API.graphql(graphqlOperation(mutations.createUser, {input: user}));
            //@ts-ignore
            let userId = response.data.createUser.id;
            const input = {
                //@ts-ignore
                recurring: this.state.checkedRecurrent,
                //@ts-ignore
                amount: (this.amount as (HTMLInputElement & React.RefObject<HTMLInputElement>)).value,
                //@ts-ignore
                currency: this.selections.currency,
                userId: userId
            };

            try {
                await mutation.mutation({input});
                this.setState({
                    formActivePanel: param,
                    formActivePanelChanged: true
                });
            } catch (e) {
                console.error(e)
            }
        }
    };

    render() {
        return (
            <MDBContainer>
                <MDBRow className="">

                    <MDBCol md="7">
                        {this.state.formActivePanel === 1 && (
                            <MDBCol md="12">
                                <h3 className="font-weight-bold pl-0 my-4 special-text">
                                    <strong> Donate </strong>
                                </h3>
                                <MDBCard color="special-color-dark" text="white" className="text-center">
                                    <MDBCardBody>
                                        <p>
                                            <MDBIcon icon="exclamation" size="2x" className="mr-3"/>
                                            Please note, we are not accepting donations at the moment as we're in our
                                            pilot stage.
                                        </p>
                                        <p>
                                            Filling the below form is indicative for us and does not oblige you to
                                            donate even if you get those
                                            pesky emails from us
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                                <Connect mutation={graphqlOperation(mutations.createDonor)}>
                                    {(mutation: any) => (<form onSubmit={this.handleSubmitClick(2, mutation)}
                                                               className="needs-validation" noValidate>
                                        <MDBInput label="First Name" className="mt-4" icon="user-circle"
                                                  name="fname"
                                                  autoFocus={true} required inputRef={ref => this.firstName = ref}>
                                            <div className="invalid-feedback  ml-4 pl-3">Provide a valid name!</div>
                                        </MDBInput>
                                        <MDBInput label="Last Name" className="mt-4" icon="-" required name="lname"
                                                  inputRef={ref => this.lastName = ref}>
                                            <div className="invalid-feedback  ml-4 pl-3">Provide a valid name!</div>
                                        </MDBInput>
                                        <MDBInput label="Email" className="mt-4" icon="at" required name="email"
                                                  type="email" inputRef={ref => this.email = ref}>
                                            <small id="emailHelp" className="form-text text-muted">
                                                We'll never share your email with anyone else.
                                            </small>
                                            <div className="invalid-feedback  ml-4 pl-3">Provide a valid email!
                                            </div>
                                        </MDBInput>
                                        <MDBInput label="Amount" className="mt-4" icon="money-bill" required
                                                  name="amount"
                                                  type="number" inputRef={ref => this.amount = ref}>
                                            <div className="invalid-feedback  ml-4 pl-3">Provide a valid amount!
                                            </div>
                                        </MDBInput>
                                        <MDBInput label="Recurring Donations" className="mt-4 p-0"
                                                  checked={this.state.checkedRecurrent}
                                                  type="checkbox" inputRef={ref => this.recurring = ref}
                                                  onChange={this.handleChecked} id="recurrent_check_id1"
                                        >
                                            <small id="emailHelp" className="form-text text-muted">
                                                Let us know if you want us to reach out to you for multiple donation
                                                rounds.
                                            </small>
                                        </MDBInput>

                                        <div>
                                            <MDBSelect
                                                className="ml-4"
                                                options={this.currencyOptions}
                                                selected="Choose your preferred currency"
                                                label="Currency (Choose all that apply)"
                                                multiple={true}
                                                color="unique-color-dark"
                                                getValue={this.handleSelectChange("currency")}
                                                required
                                            />
                                        </div>
                                        <MDBBtn color="elegant" rounded className="float-right" type="submit">
                                            submit
                                        </MDBBtn>
                                    </form>)}
                                </Connect>

                            </MDBCol>
                        )}
                        {this.state.formActivePanel === 2 && (
                            <MDBCol md="12">
                                <h2 className="text-center font-weight-bold my-4 special-text">
                                    Registration completed!
                                </h2>
                            </MDBCol>
                        )}
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    };
}

// class MessageUs extends React.Component<{}, PanelState> {
//     private firstName: React.RefObject<HTMLInputElement>;
//     private lastName: React.RefObject<HTMLInputElement>;
//     private email: React.RefObject<HTMLInputElement>;
//     private message: React.RefObject<HTMLInputElement>;
//
//     constructor(props: any) {
//         super(props);
//
//         this.state = {
//             formActivePanel: 1,
//             formActivePanelChanged: false,
//         };
//
//         this.firstName = React.createRef();
//         this.lastName = React.createRef();
//         this.email = React.createRef();
//         this.message = React.createRef();
//     }
//
//     swapFormActive = (param: number) => (e: React.FocusEvent<HTMLFormElement>) => {
//         //@ts-ignore
//         this.setState({
//             formActivePanel: param,
//             formActivePanelChanged: true
//         });
//     };
//
//     handleNextPrevClick = (param: number) => (e: React.FocusEvent<HTMLFormElement>) => {
//         e.preventDefault();
//         e.target.className += " was-validated";
//         //@ts-ignore
//         this.setState({
//             formActivePanel: param,
//             formActivePanelChanged: true
//         });
//     };
//
//     handleSubmission = () => {
//         alert('Form submitted!');
//     };
//
//     calculateAutofocus = () => {
//         //@ts-ignore
//         if (this.state.formActivePanelChanged) {
//             return true
//         }
//     };
//
//     render() {
//         return (
//             <MDBContainer>
//                 <MDBRow className="pt-5 justify-content-center">
//                     <MDBCol md="7">
//                         {this.state.formActivePanel === 1 && (
//                             <MDBCol md="12">
//                                 <form onSubmit={this.handleNextPrevClick(2)}
//                                       className="needs-validation" noValidate>
//                                     <h3 className="font-weight-bold pl-0 my-4 special-text">
//                                         <strong> Ask us a question! </strong>
//                                     </h3>
//                                     <MDBInput label="First Name" className="mt-4" icon="user-circle"
//                                               name="fname"
//                                               autoFocus={true} required inputRef={ref => this.firstName = ref}>
//                                         <div className="invalid-feedback  ml-4 pl-3">Provide a valid name!</div>
//                                     </MDBInput>
//                                     <MDBInput label="Last Name" className="mt-4" icon="-" required name="lname"
//                                               inputRef={ref => this.lastName = ref}>
//                                         <div className="invalid-feedback  ml-4 pl-3">Provide a valid name!</div>
//                                     </MDBInput>
//                                     <MDBInput label="Email" className="mt-4" icon="at" required name="email"
//                                               type="email" inputRef={ref => this.email = ref}>
//                                         <small id="emailHelp" className="form-text text-muted">
//                                             This is so we know how to contact you - we'll never share your email with
//                                             anyone else.
//                                         </small>
//                                         <div className="invalid-feedback  ml-4 pl-3">Provide a valid email!
//                                         </div>
//                                     </MDBInput>
//                                     <MDBInput label="Message" className="mt-4" icon="pencil-alt" required name="message"
//                                               type="textarea" inputRef={ref => this.message = ref}>
//                                         <div className="invalid-feedback  ml-4 pl-3">Provide a valid message
//                                         </div>
//                                     </MDBInput>
//
//                                     <MDBBtn color="elegant" rounded className="float-right" type="submit">
//                                         submit
//                                     </MDBBtn>
//                                 </form>
//
//                             </MDBCol>
//                         )}
//                         {this.state.formActivePanel === 2 && (
//                             <MDBCol md="12">
//                                 <h2 className="text-center font-weight-bold my-4 special-text">
//                                     Registration completed!
//                                 </h2>
//                             </MDBCol>
//                         )}
//                     </MDBCol>
//                 </MDBRow>
//             </MDBContainer>
//         );
//     };
// }

