import React from 'react';
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBCollapse,
    MDBNavItem,
    MDBNavLink,
    MDBIcon,
    MDBContainer,
    MDBHamburgerToggler
} from 'mdbreact';

export interface ICustomNavbarProps {
    colour?: string;
    dark: boolean;
    style?: {};
}

export interface ICustomNavbarState {
    collapse: boolean;
}

class MainNavbar extends React.Component<ICustomNavbarProps, ICustomNavbarState> {
    constructor(props:ICustomNavbarProps) {
        super(props);
        this.state = {
            collapse: false,
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    render() {
        return (
            <div className={this.props.colour} style={this.props.style}>
                    <header>
                        <MDBNavbar expand="lg" className="" dark={this.props.dark} light={!this.props.dark}>
                            <MDBNavbarBrand href="/" className="brand ml-sm-2 ml-md-5 pl-md-5 ">
                                VHP
                            </MDBNavbarBrand>
                            <MDBNavbarToggler onClick={this.onClick} />
                            <MDBCollapse isOpen={this.state.collapse} navbar>
                                <MDBNavbarNav left>
                                    <MDBNavItem>
                                            <a href="https://blog.visiblehandsproject.com" className="nav-link Ripple-parent header-text d-flex flex-row-reverse">
                                                <div data-test="waves" className="Ripple "
                                                     style={{top: "0px", left: "0px", width: "0px", height: "0px"}}></div>
                                                Blog</a>
                                    </MDBNavItem>
                                    <MDBNavItem>
                                        <MDBNavLink to="/who-we-are" className="header-text d-flex flex-row-reverse">Who we are</MDBNavLink>
                                    </MDBNavItem>
                                    <MDBNavItem>
                                        <MDBNavLink to="/project-plan" className="header-text d-flex flex-row-reverse">Project Plan</MDBNavLink>
                                    </MDBNavItem>
                                    <MDBNavItem>
                                        <MDBNavLink to="/contact-us/volunteer" className="header-text d-flex flex-row-reverse">Get Involved!</MDBNavLink>
                                    </MDBNavItem>
                                </MDBNavbarNav>
                            </MDBCollapse>
                        </MDBNavbar>
                    </header>
            </div>
        );
    }
}

export class SubNavbar extends MainNavbar {
    render() {
        return (
            <div className={this.props.colour}>
                <header>
                    <MDBNavbar expand="lg" className="d-flex justify-content-end" dark={this.props.dark} light={!this.props.dark} >
                        <MDBNavbarToggler color="#0f0f0f" onClick={this.onClick} right>
                            <MDBIcon icon="expand-arrows-alt" />
                        </MDBNavbarToggler>
                        <MDBCollapse isOpen={this.state.collapse} navbar>
                            <MDBNavbarNav right>
                                <MDBNavItem>
                                    <MDBNavLink to="/core-principles/sustainability" className="header-text d-flex flex-row-reverse" active={true}>Sustainable Philanthropy</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="/core-principles/leverage" className="header-text d-flex flex-row-reverse">Skill-based volunteering</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="/core-principles/transforming" className="header-text d-flex flex-row-reverse" >Transforming Businesses</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="/core-principles/discussion" className="header-text d-flex flex-row-reverse">Discussion Platform</MDBNavLink>
                                </MDBNavItem>
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBNavbar>
                </header>
            </div>
        );
    }
}

export default MainNavbar;