import * as React from 'react';
import classnames from "classnames";
import MainNavbar from "../components/navbar";
import {CustomFooter} from "../components/custom_footer";
import {IWhoWeAreProps} from "./who-we-are";

export class ProjectPlan extends React.Component<any>{

    constructor(props: IWhoWeAreProps) {
        super(props);
    }

    handleRedirect(path: string) {
        this.props.history.push(path);
    }

    render() {
        return (
            <>
                <div className={classnames("theme-background", "min-vh-100 d-flex flex-column")}>
                    <MainNavbar colour="plain-navbar" dark={false}/>

                    <div className="col-12 text-center animated-large-text">
                       Our Journey Milestones
                    </div>
                    <div className="project-plan">
                        <div>
                            <p><b>Foundational activities. We're desperately trying to get set up and running,
                                registering our charity, hiring and funding the initial phases to push our ideas and thoughts.
                                Transparently, that includes getting this very page you're reading live and running!</b></p>
                            <p>Planning our case study. We have ideas, but we're quite honest with ourselves that other
                                people have the same. We need to spend some time making sure we plan our first case study
                                just right in order to validate our ideas.</p>
                            <p>Case study implementation. Depending on the length of our case stud, this can take a
                                while but we will loop in all relevant parties to trial our ideas with a chosen business
                                and dedicated entrepreneur. This informs us of whether our principles are a hard "no",
                                something to be improved upon or something that just works.</p>
                            <p>Public charity go live. Here's where we go live and you get involved. We plan a set of
                                further businesses we can take on depending on fundraising, volunteer commitment and
                                business interest.
                                As we intend on running in cycles, this phase may easily take a couple of years.
                                This phase allows us to refine our operations even further and keep our eyes on the ball</p>
                            <p> At this stage, we're fully operational and we can extend operations to other cities and
                                localities. We hope you'll be there at this stage on the journey with us. </p>

                        </div>
                    </div>
                    <CustomFooter classNames=""/>
                </div>
            </>
        );
    }
}