/* tslint:disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  firstName: string,
  secondName: string,
  email: string,
};

export type ModelUserConditionInput = {
  firstName?: ModelStringInput | null,
  secondName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type UpdateUserInput = {
  id: string,
  firstName?: string | null,
  secondName?: string | null,
  email?: string | null,
};

export type DeleteUserInput = {
  id?: string | null,
};

export type CreateDonorInput = {
  id?: string | null,
  userId: string,
  recurring: boolean,
  amount?: number | null,
  currency?: string | null,
  donorUserId?: string | null,
};

export type ModelDonorConditionInput = {
  userId?: ModelIDInput | null,
  recurring?: ModelBooleanInput | null,
  amount?: ModelIntInput | null,
  currency?: ModelStringInput | null,
  and?: Array< ModelDonorConditionInput | null > | null,
  or?: Array< ModelDonorConditionInput | null > | null,
  not?: ModelDonorConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateDonorInput = {
  id: string,
  userId?: string | null,
  recurring?: boolean | null,
  amount?: number | null,
  currency?: string | null,
  donorUserId?: string | null,
};

export type DeleteDonorInput = {
  id?: string | null,
};

export type CreateVolunteerInput = {
  id?: string | null,
  userId: string,
  skills?: Array< Field | null > | null,
  educationLevel?: Education | null,
  availabilityHours?: Hours | null,
  availabilityPeriod?: Period | null,
  volunteerUserId?: string | null,
};

export enum Field {
  ACCOUNTANCY = "ACCOUNTANCY",
  AGRICULTURE = "AGRICULTURE",
  ARCHITECTURE = "ARCHITECTURE",
  ARTS = "ARTS",
  BUSINESS_ADMINISTRATION = "BUSINESS_ADMINISTRATION",
  COMMUNICATIONS = "COMMUNICATIONS",
  CONSTRUCTION = "CONSTRUCTION",
  EDUCATION = "EDUCATION",
  ENGINEERING = "ENGINEERING",
  ENVIRONMENT = "ENVIRONMENT",
  FINANCE = "FINANCE",
  GOVERNMENT = "GOVERNMENT",
  HEALTH_MEDICINE = "HEALTH_MEDICINE",
  INFORMATION_TECHNOLOGY = "INFORMATION_TECHNOLOGY",
  LAW = "LAW",
  SCIENCES = "SCIENCES",
  SOCIAL_IMPACT = "SOCIAL_IMPACT",
}


export enum Education {
  HIGH_SCHOOL = "HIGH_SCHOOL",
  UNDERGRAD = "UNDERGRAD",
  MASTERS = "MASTERS",
  PHD = "PHD",
}


export enum Hours {
  ONE_HOUR = "ONE_HOUR",
  TWO_HOURS = "TWO_HOURS",
  THREE_HOURS = "THREE_HOURS",
  FIVE_HOURS = "FIVE_HOURS",
}


export enum Period {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
}


export type ModelVolunteerConditionInput = {
  userId?: ModelIDInput | null,
  skills?: ModelFieldListInput | null,
  educationLevel?: ModelEducationInput | null,
  availabilityHours?: ModelHoursInput | null,
  availabilityPeriod?: ModelPeriodInput | null,
  and?: Array< ModelVolunteerConditionInput | null > | null,
  or?: Array< ModelVolunteerConditionInput | null > | null,
  not?: ModelVolunteerConditionInput | null,
};

export type ModelFieldListInput = {
  eq?: Array< Field | null > | null,
  ne?: Array< Field | null > | null,
  contains?: Field | null,
  notContains?: Field | null,
};

export type ModelEducationInput = {
  eq?: Education | null,
  ne?: Education | null,
};

export type ModelHoursInput = {
  eq?: Hours | null,
  ne?: Hours | null,
};

export type ModelPeriodInput = {
  eq?: Period | null,
  ne?: Period | null,
};

export type UpdateVolunteerInput = {
  id: string,
  userId?: string | null,
  skills?: Array< Field | null > | null,
  educationLevel?: Education | null,
  availabilityHours?: Hours | null,
  availabilityPeriod?: Period | null,
  volunteerUserId?: string | null,
};

export type DeleteVolunteerInput = {
  id?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  secondName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelDonorFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  recurring?: ModelBooleanInput | null,
  amount?: ModelIntInput | null,
  currency?: ModelStringInput | null,
  and?: Array< ModelDonorFilterInput | null > | null,
  or?: Array< ModelDonorFilterInput | null > | null,
  not?: ModelDonorFilterInput | null,
};

export type ModelVolunteerFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  skills?: ModelFieldListInput | null,
  educationLevel?: ModelEducationInput | null,
  availabilityHours?: ModelHoursInput | null,
  availabilityPeriod?: ModelPeriodInput | null,
  and?: Array< ModelVolunteerFilterInput | null > | null,
  or?: Array< ModelVolunteerFilterInput | null > | null,
  not?: ModelVolunteerFilterInput | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser:  {
    __typename: "User",
    id: string,
    firstName: string,
    secondName: string,
    email: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser:  {
    __typename: "User",
    id: string,
    firstName: string,
    secondName: string,
    email: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser:  {
    __typename: "User",
    id: string,
    firstName: string,
    secondName: string,
    email: string,
  } | null,
};

export type CreateDonorMutationVariables = {
  input: CreateDonorInput,
  condition?: ModelDonorConditionInput | null,
};

export type CreateDonorMutation = {
  createDonor:  {
    __typename: "Donor",
    id: string,
    userId: string,
    recurring: boolean,
    amount: number | null,
    currency: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type UpdateDonorMutationVariables = {
  input: UpdateDonorInput,
  condition?: ModelDonorConditionInput | null,
};

export type UpdateDonorMutation = {
  updateDonor:  {
    __typename: "Donor",
    id: string,
    userId: string,
    recurring: boolean,
    amount: number | null,
    currency: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type DeleteDonorMutationVariables = {
  input: DeleteDonorInput,
  condition?: ModelDonorConditionInput | null,
};

export type DeleteDonorMutation = {
  deleteDonor:  {
    __typename: "Donor",
    id: string,
    userId: string,
    recurring: boolean,
    amount: number | null,
    currency: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type CreateVolunteerMutationVariables = {
  input: CreateVolunteerInput,
  condition?: ModelVolunteerConditionInput | null,
};

export type CreateVolunteerMutation = {
  createVolunteer:  {
    __typename: "Volunteer",
    id: string,
    userId: string,
    skills: Array< Field | null > | null,
    educationLevel: Education | null,
    availabilityHours: Hours | null,
    availabilityPeriod: Period | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type UpdateVolunteerMutationVariables = {
  input: UpdateVolunteerInput,
  condition?: ModelVolunteerConditionInput | null,
};

export type UpdateVolunteerMutation = {
  updateVolunteer:  {
    __typename: "Volunteer",
    id: string,
    userId: string,
    skills: Array< Field | null > | null,
    educationLevel: Education | null,
    availabilityHours: Hours | null,
    availabilityPeriod: Period | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type DeleteVolunteerMutationVariables = {
  input: DeleteVolunteerInput,
  condition?: ModelVolunteerConditionInput | null,
};

export type DeleteVolunteerMutation = {
  deleteVolunteer:  {
    __typename: "Volunteer",
    id: string,
    userId: string,
    skills: Array< Field | null > | null,
    educationLevel: Education | null,
    availabilityHours: Hours | null,
    availabilityPeriod: Period | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser:  {
    __typename: "User",
    id: string,
    firstName: string,
    secondName: string,
    email: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetDonorQueryVariables = {
  id: string,
};

export type GetDonorQuery = {
  getDonor:  {
    __typename: "Donor",
    id: string,
    userId: string,
    recurring: boolean,
    amount: number | null,
    currency: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type ListDonorsQueryVariables = {
  filter?: ModelDonorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDonorsQuery = {
  listDonors:  {
    __typename: "ModelDonorConnection",
    items:  Array< {
      __typename: "Donor",
      id: string,
      userId: string,
      recurring: boolean,
      amount: number | null,
      currency: string | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        secondName: string,
        email: string,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetVolunteerQueryVariables = {
  id: string,
};

export type GetVolunteerQuery = {
  getVolunteer:  {
    __typename: "Volunteer",
    id: string,
    userId: string,
    skills: Array< Field | null > | null,
    educationLevel: Education | null,
    availabilityHours: Hours | null,
    availabilityPeriod: Period | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type ListVolunteersQueryVariables = {
  filter?: ModelVolunteerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVolunteersQuery = {
  listVolunteers:  {
    __typename: "ModelVolunteerConnection",
    items:  Array< {
      __typename: "Volunteer",
      id: string,
      userId: string,
      skills: Array< Field | null > | null,
      educationLevel: Education | null,
      availabilityHours: Hours | null,
      availabilityPeriod: Period | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        secondName: string,
        email: string,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type OnCreateUserSubscription = {
  onCreateUser:  {
    __typename: "User",
    id: string,
    firstName: string,
    secondName: string,
    email: string,
  } | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser:  {
    __typename: "User",
    id: string,
    firstName: string,
    secondName: string,
    email: string,
  } | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser:  {
    __typename: "User",
    id: string,
    firstName: string,
    secondName: string,
    email: string,
  } | null,
};

export type OnCreateDonorSubscription = {
  onCreateDonor:  {
    __typename: "Donor",
    id: string,
    userId: string,
    recurring: boolean,
    amount: number | null,
    currency: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type OnUpdateDonorSubscription = {
  onUpdateDonor:  {
    __typename: "Donor",
    id: string,
    userId: string,
    recurring: boolean,
    amount: number | null,
    currency: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type OnDeleteDonorSubscription = {
  onDeleteDonor:  {
    __typename: "Donor",
    id: string,
    userId: string,
    recurring: boolean,
    amount: number | null,
    currency: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type OnCreateVolunteerSubscription = {
  onCreateVolunteer:  {
    __typename: "Volunteer",
    id: string,
    userId: string,
    skills: Array< Field | null > | null,
    educationLevel: Education | null,
    availabilityHours: Hours | null,
    availabilityPeriod: Period | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type OnUpdateVolunteerSubscription = {
  onUpdateVolunteer:  {
    __typename: "Volunteer",
    id: string,
    userId: string,
    skills: Array< Field | null > | null,
    educationLevel: Education | null,
    availabilityHours: Hours | null,
    availabilityPeriod: Period | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};

export type OnDeleteVolunteerSubscription = {
  onDeleteVolunteer:  {
    __typename: "Volunteer",
    id: string,
    userId: string,
    skills: Array< Field | null > | null,
    educationLevel: Education | null,
    availabilityHours: Hours | null,
    availabilityPeriod: Period | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      secondName: string,
      email: string,
    } | null,
  } | null,
};
