import * as React from "react";
import {MDBCol, MDBContainer, MDBFooter, MDBRow} from "mdbreact";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faLinkedinIn, faTwitter} from "@fortawesome/free-brands-svg-icons";
import classnames from "classnames";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";

export interface ICustomFooterProps extends  RouteComponentProps<any>{
    classNames: string;

}

class Footer extends React.Component<ICustomFooterProps, {}>{

    handleRedirect = (path: string) => {
        this.props.history.push(path);
    };

    render(){
        let pointerStyle = {cursor: "pointer"};
       return <MDBFooter color="elegant-color" className={classnames("font-small", this.props.classNames)}>
        <MDBContainer className="text-center text-md-left pt-3">
            <MDBRow className="d-flex align-items-center">
                <MDBCol md="8" lg="8">
                    <p className="text-center text-md-left grey-text">
                        &copy; {new Date().getFullYear()} Copyright:{" "}
                        <a href="https://www.MDBootstrap.com">The Visible Hands Project </a>
                    </p>
                </MDBCol>
                <MDBCol md="4" lg="4" className="ml-lg-0">
                    <div className="text-center text-md-right">
                        <ul className="list-unstyled list-inline">
                            <li className="list-inline-item">
                                <a className="btn-sm" href="https://www.instagram.com/visiblehandsproject">
                                    <FontAwesomeIcon icon={faInstagram}/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    </MDBFooter>;
    }
};


const CustomFooter = withRouter(Footer);
export {CustomFooter};